<template>
  <label>
    {{ label }}
    <br v-if="labelPosition != 'left'" />
    <select @change="$emit('change', $event.target.value)" v-model="status">
      <option v-for="s in statusList" :value="s" :key="s">{{ captionList[s] }}</option>
    </select>
  </label>
</template>

<script>
import { STATUS_VALUE, STATUS_CAPTION } from "@/config/settings";
export default {
  name: "StatusSelector",
  props: { label: String, labelPosition: String, statusIn: String },
  data: function () {
    return { statusList: Array, captionList: Array, status: String };
  },

  created: function () {
    this.statusList = STATUS_VALUE;
    this.captionList = STATUS_CAPTION;

    if (this.statusIn) this.status = this.statusIn;
    else this.status = this.statusList[0];

    this.$emit("change", this.status);
  },
};
</script>
