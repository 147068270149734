<template>
  <div>
    <h3 class="center">Люди</h3>
    <div class="high">
      <StatusSelector label="Критерий отбора" labelPosition="left" @change="status = $event" />
    </div>
    <div v-if="loading" class="body">
      <Loader />
    </div>

    <div v-else class="body">
      <div class="reminder smalltext">работает сортировка по столбцам</div>

      <table class="t_table nofixed">
        <tr style="user-select: none; -webkit-user-select: none">
          <th>N</th>
          <th style="width: 25%" ref="surname" @click="sort('surname')" class="pointer">ФИО</th>
          <th ref="nickname" @click="sort('nickname')" class="pointer">Ник</th>
          <th>Роли</th>
          <th ref="job_name" @click="sort('job_name')" class="pointer">Должность</th>
          <th ref="phone" @click="sort('phone')" class="pointer">Телефон</th>
        </tr>
        <template v-for="(row, y) in filter(people)">
          <tr :key="y" @click="windowPeopleOpen(row.id)" class="user">
            <td>{{ y + 1 }}</td>
            <td :title="`${row.surname} ${row.name} ${row.patronymic}`">
              {{ row.surname }} {{ row.name }}
            </td>
            <td>
              {{ row.auto_table ? "\u{1F170}" : null }}
              {{ row.nickname ? row.nickname : "" }}
            </td>
            <td>{{ rolesOut(row.roles) }}</td>
            <td>{{ row.job_name }}</td>
            <td>{{ row.phone }}</td>
          </tr>
        </template>
        <template v-if="filter(people).length == 0">
          <tr>
            <td colspan="8" class="center">нет данных по выбранным параметрам</td>
          </tr>
        </template>
        <tr>
          <td colspan="300">
            <div class="add" @click="windowPeopleOpen(0)">добавить человека</div>
          </td>
        </tr>
      </table>
    </div>

    <PeopleWindow v-on:hide="windowPeopleClose" :showFlag="showFlag" :personIn="person" />
    <br />
    <br />
  </div>
</template>

<style scoped>
.nofixed {
  table-layout: auto;
}
.add {
  cursor: pointer;
  padding: 8px;
}
.add:hover {
  text-decoration: underline;
}
tr.user:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>
<script>
import Loader from "@/components/Loader.vue";
import StatusSelector from "@/components/StatusSelector.vue";
import { request } from "@/components-js/requestSrv";
// import { STATUS_VALUE, STATUS_CAPTION } from "@/config/settings";
import PeopleWindow from "@/components/PeopleWindow.vue";

export default {
  components: { Loader, StatusSelector, PeopleWindow },
  data() {
    return {
      loading: false,
      people: [],
      x: 1,
      person: null,
      showFlag: false,
      // selected in filter
      status: String,
    };
  },

  async created() {
    this.tableDataRetrieve();
  },

  methods: {
    async tableDataRetrieve() {
      this.loading = true;
      this.people = await request("/api/report/peoplelist");
      this.loading = false;
    },

    rolesOut(rolesArr) {
      let res = "";
      for (let i in rolesArr) {
        res += rolesArr[i];
        res += i < rolesArr.length - 1 ? ", " : "";
      }
      return res;
    },

    filter(people) {
      return people.filter((i) => i.status == this.status);
    },

    sort(col) {
      if (!this.people.length) return;
      this.x *= -1;
      if (col) {
        this.people.sort((a, b) => {
          if (
            (parseInt(a[col]) && parseInt(a[col]).length == a[col].length) ||
            (parseInt(b[col]) && parseInt(b[col]).length == b[col].length)
          ) {
            // number sort
            if (parseInt(a[col]) < parseInt(b[col])) return -1 * this.x;
            if (parseInt(a[col]) > parseInt(b[col])) return 1 * this.x;
            return 0;
          } else {
            // text sort
            if (a[col] < b[col]) return -1 * this.x;
            if (a[col] > b[col]) return 1 * this.x;
            return 0;
          }
        });

        for (let name in this.$refs) {
          this.$refs[name].style.fontWeight = "normal";
        }
        this.$refs[col].style.fontWeight = "bold";
      }
    },

    windowPeopleOpen(id) {
      // if person selected
      if (id) {
        const pos = this.people.findIndex((i) => i.id == id);
        this.person = this.people[pos];
      } else {
        this.person = null;
      }
      // show window at least
      this.showFlag = true;
    },

    async windowPeopleClose(person) {
      this.showFlag = false;
      if (!person.id) return;
      await this.tableDataRetrieve();
    },
  },
};
</script>
