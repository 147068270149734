<template>
  <div v-if="showFlag">
    <section class="modalTurn">
      <div class="modalTurn-content">
        <div class="modalWin">
          <h3 v-if="person.id">Редактировать сотрудника</h3>
          <h3 v-else>Добавить сотрудника</h3>
          <table style="width: 100%">
            <tr>
              <td class="R">Ник</td>
              <td class="L"><input type="text" v-model="person.nickname" /></td>
            </tr>
            <tr>
              <td class="R">Статус</td>
              <td class="L">
                <StatusSelector
                  label=""
                  labelPosition="left"
                  :statusIn="person.status"
                  @change="person.status = $event"
                />
              </td>
            </tr>
            <tr>
              <td class="R">Должность</td>
              <td class="L">
                <JobSelector
                  label=""
                  labelPosition="left"
                  :id_job="person.id_job"
                  @change="person.id_job = $event"
                />
              </td>
            </tr>
            <tr>
              <td class="R">Авторасписание</td>
              <td class="L">
                <input
                  type="checkbox"
                  v-model="person.auto_table"
                  style="vertical-align: middle"
                />
              </td>
            </tr>
            <tr>
              <td colspan="300" style="padding: 0.5rem 0"><hr /></td>
            </tr>
            <tr>
              <td class="R">Фамилия</td>
              <td class="L">
                <input type="text" v-model="person.surname" ref="surname" />
              </td>
            </tr>
            <tr>
              <td class="R">Имя</td>
              <td class="L">
                <input type="text" v-model="person.name" ref="name" />
              </td>
            </tr>
            <tr>
              <td class="R">Отчество</td>
              <td class="L">
                <input type="text" v-model="person.patronymic" />
              </td>
            </tr>
            <tr>
              <td class="R">Пол</td>
              <td class="L">
                <GenderSelector
                  label=""
                  labelPosition="left"
                  :gender="person.gender"
                  @change="person.gender = $event"
                />
              </td>
            </tr>
            <tr>
              <td class="R">ДР</td>
              <td class="L">
                <DateSelector
                  label=""
                  labelPosition="left"
                  :val="person.birth"
                  @change="person.birth = $event"
                />
              </td>
            </tr>
            <tr>
              <td class="R">Телефон</td>
              <td class="L">
                <input type="text" v-model="person.phone" ref="phone" />
              </td>
            </tr>
            <tr>
              <td class="R">Дата прихода</td>
              <td class="L">
                <DateSelector
                  label=""
                  labelPosition="left"
                  :val="person.date_hire"
                  @change="person.date_hire = $event"
                />
              </td>
            </tr>
            <tr>
              <td colspan="300" style="padding: 0.5rem 0"><hr /></td>
            </tr>
            <tr>
              <td class="R">Роли:</td>
              <td class="L">
                <input type="checkbox" v-model="roleUser" style="vertical-align: middle" />
                User&nbsp;&nbsp;&nbsp;
                <input type="checkbox" v-model="roleAdmin" style="vertical-align: middle" />
                Admin
              </td>
            </tr>
            <tr>
              <td class="R">Логин</td>
              <td class="L">
                <input type="text" disabled="true" v-model="person.login" />
              </td>
            </tr>
            <tr>
              <td class="R">Пароль</td>
              <td class="L">
                <input
                  type="password"
                  v-model.trim="person.pass"
                  ref="pass"
                  @focus="setType('pass', 'text')"
                  @blur="setType('pass', 'password')"
                />
              </td>
            </tr>
            <tr>
              <td class="R">Часовой пояс</td>
              <td class="L">
                {{ 0 > person.timezone_hour ? "-" : "+" }}{{ person.timezone_hour }}
                {{ person.timezone_min > 0 ? ":" + person.timezone_min : "" }}
              </td>
            </tr>
          </table>
          <br />
          <div class="err">{{ errMessage }}&nbsp;</div>
          <br />
          <button v-if="person.id" v-on:click="Delete()" class="btn warn">Удалить</button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button v-on:click="cancel()" class="btn cancel">Отмена</button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button ref="applyButton" v-on:click="Apply()" class="btn">
            {{ person.id ? "Применить" : "Добавить" }}
          </button>
        </div>
      </div>
    </section>
  </div>
</template>
<style scoped>
div {
  padding-top: 5px;
  padding-bottom: 5px;
  vertical-align: middle;
}
.col-revers {
  display: flex;
  flex-direction: row-reverse;
}
td.R {
  text-align: right;
}
td.L {
  text-align: left;
}
table {
  border-spacing: 0.4rem;
}
</style>
<script>
import DateSelector from "@/components/DateSelector.vue";
import JobSelector from "@/components/JobSelector";
import GenderSelector from "@/components/GenderSelector";
import StatusSelector from "@/components/StatusSelector";
import {
  STATUS_VALUE,
  GENDER_VALUE_FULL,
  TIMEZONE_DEFAULT_HOUR,
  TIMEZONE_DEFAULT_MIN,
} from "@/config/settings";
import { dateFormatJS } from "@/components-js/dateFormat";
import { request } from "@/components-js/requestSrv";
import {
  maskPhoneEngine,
  maskPhoneFormat,
  maskPhoneLogin,
  maskPhoneValidity,
} from "@/components-js/maskPhone";

export default {
  name: "PeopleWindow",
  components: { DateSelector, JobSelector, GenderSelector, StatusSelector },
  props: {
    showFlag: Boolean,
    personIn: Object,
  },

  data() {
    return {
      errMessage: null,
      mask: null,
      person: { phone: null, status: null },
      passMinLength: 4,
      roleUser: null,
      roleAdmin: null,
    };
  },
  // directives: {
  //   phonemask: {
  //     update: function (el) {
  //       console.log("Directive value is", el.value);
  //       console.log("maskFormat value is", maskFormat(el.value));
  //     },
  //   },
  // },
  watch: {
    "person.phone": function (newValue) {
      this.person.login = maskPhoneLogin(newValue);
      this.person.phone = maskPhoneFormat(this.person.phone);
      if (!this.person.pass)
        if (this.person.login.length >= this.passMinLength) {
          this.person.pass = "";
          for (let i = 1; i <= this.passMinLength; i++) {
            this.person.pass += this.person.login.charAt(this.person.login.length - i);
          }
        }
    },

    "person.status": function (newValue) {
      if (newValue == "fired") {
        this.person.auto_table = false;
      }
    },

    showFlag: function (newValue) {
      // window appeared
      if (newValue) {
        if (!this.person) this.person = new Object();
        // after end of render
        this.$nextTick(function () {
          maskPhoneEngine(this.$refs.phone);
        });

        if (this.personIn) {
          // create copy
          for (let i in this.personIn) {
            this.person[i] = this.personIn[i];
          }
          // set roles
          this.roleUser = this.person.roles ? this.person.roles.includes("user") : false;
          this.roleAdmin = this.person.roles ? this.person.roles.includes("admin") : false;
        } else {
          this.initDefault();
        }
      }
    },
  },

  methods: {
    initDefault() {
      this.person.id = 0;
      this.person.name = "Имя";
      this.person.surname = "Фамилия";
      this.person.patronymic = "Отчество";
      this.person.nickname = "Милаха";
      this.person.birth = "2000-01-01";
      this.person.id_job = 0;
      this.person.phone = "";
      this.person.status = STATUS_VALUE[0];
      this.person.gender = GENDER_VALUE_FULL[0];
      this.person.login = null;
      this.person.pass = null;
      this.person.date_hire = dateFormatJS(new Date());
      this.person.timezone_hour = TIMEZONE_DEFAULT_HOUR;
      this.person.timezone_min = TIMEZONE_DEFAULT_MIN;
      this.person.auto_table = true;

      this.roleUser = true;
      this.roleAdmin = false;
    },

    setType(ref, type) {
      this.$refs[ref].setAttribute("type", type);
    },

    async Apply() {
      // console.log(this.person);

      if (!this.person.surname.replaceAll(" ", "").length) {
        this.$refs.surname.classList.add("warn-border");
        setTimeout(() => this.$refs.surname.classList.remove("warn-border"), 300);
        return;
      }

      if (!this.person.name.replaceAll(" ", "").length) {
        this.$refs.name.classList.add("warn-border");
        setTimeout(() => this.$refs.name.classList.remove("warn-border"), 300);
        return;
      }

      if (!maskPhoneValidity(this.person.phone)) {
        this.$refs.phone.classList.add("warn-border");
        setTimeout(() => this.$refs.phone.classList.remove("warn-border"), 300);
        return;
      }

      if (
        !this.person.pass ||
        this.person.pass.replaceAll(" ", "").length < this.passMinLength
      ) {
        this.$refs.pass.classList.add("warn-border");
        setTimeout(() => this.$refs.pass.classList.remove("warn-border"), 300);
        this.errMessage = `Пароль не менее ${this.passMinLength} символов.`;
        setTimeout(() => (this.errMessage = null), 1500);
        return;
      }

      const roles = [];
      if (this.roleUser) roles.push("user");
      if (this.roleAdmin) roles.push("admin");
      this.person.roles = roles;

      if (this.person.id) {
        const res = await request("/api/user/update", "POST", this.person);
        if (res.affectedRows) this.windowClose();
        else {
          this.$refs.applyButton.classList.add("warn");
          setTimeout(() => this.$refs.applyButton.classList.remove("warn"), 300);
          return;
        }
      } else {
        const res = await request("/api/user/insert", "POST", this.person);
        if (res.affectedRows == 1 && res.insertId) {
          this.person.id = res.insertId;
          this.windowClose();
        }
      }
    },

    async Delete() {
      let res = await request("/api/user/deletecheck", "POST", {
        id: this.person.id,
      });
      if (res[0].cnt) {
        this.errMessage = res[0].cnt + " смен с участием персоны";
        setTimeout(() => (this.errMessage = null), 1500);
        return;
      }
      res = await request("/api/user/delete", "DELETE", { id: this.person.id });
      if (res.affectedRows) {
        this.person.id *= -1;
        this.windowClose();
      } else {
        this.errMessage = "ошибка удаления";
        setTimeout(() => (this.errMessage = null), 1500);
      }
    },

    cancel() {
      this.person.id = 0;
      this.windowClose();
    },

    windowClose() {
      this.$emit("hide", this.person);
    },
  },
};
</script>
