<template>
  <label>
    {{ label }}
    <br v-if="labelPosition != 'left'" />
    <select @change="$emit('change', $event.target.value)" v-model="gender">
      <option v-for="g in GENDER_VALUE_filtered" :key="g" :value="g">
        {{ GENDER_CAPTION[g] }}
      </option>
    </select>
    <input type="checkbox" v-model="extflag" disabled />
  </label>
</template>

<script>
import { GENDER_VALUE_FULL, GENDER_VALUE_HOMOPHOBIAN, GENDER_CAPTION } from "@/config/settings";
export default {
  name: "GenderSelector",
  props: {
    label: String,
    labelPosition: String,
    gender: String,
    extflag: Number,
  },

  data: function () {
    return {
      GENDER_VALUE_FULL,
      GENDER_VALUE_HOMOPHOBIAN,
      GENDER_VALUE_filtered: [],
      GENDER_CAPTION,
    };
  },

  mounted() {
    this.genderInit();
  },

  watch: {
    extflag: function () {
      this.genderInit();
    },
  },

  methods: {
    genderInit() {
      if (this.extflag) this.GENDER_VALUE_filtered = GENDER_VALUE_FULL;
      else this.GENDER_VALUE_filtered = GENDER_VALUE_HOMOPHOBIAN;
    },
  },
};
</script>
